import Generic from '@luketclancy/otterly/units/generic.js'
export default {
	...Generic,
	dive(e, h = {}){
		let aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa = (e, h)=>{
			e.preventDefault()
			let act
			if(h.behavior == undefined){h.behavior = 'default'}
			if(act = this.diveBehaviors[h.behavior]){
				return act.bind(this)(e, h)
			} else {
				console.error("bad behavior type for a dive")
			}
		}
		let out = aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa.bind(this)(e, h)
		let aaa = () => {
			if(h['resetLoading']){
				midflip.kill_any_loading_butts_below(this.el)
			}
		}
		out.then(aaa, aaa)
	},
}