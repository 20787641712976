import Otty from '@luketclancy/otterly/otty'


export default {
	...Otty,
	//this method has been changed to try and account for the insanity of the Table Of Contents.
	//Namely, it waits for any relevant editors to be loaded before it continues
	async scrollToLocationHashElement(loc){
		if(! loc.hash){ return false }

		let waitaway = Array.from(document.querySelectorAll('[data-hash-wait]'))

		for(let hw of waitaway) {
			if(hw?._unit?.editorLoadedPromise){
				await hw._unit.editorLoadedPromise	
			}
		}

		let e = document.getElementById(decodeURIComponent(loc.hash.slice(1)))
		if(e){
			await this.waitForImages()
			e.scrollIntoView()
			return true
		}
		return false
	},

	//this method has been changed so that even if we cant find the hash, we 
	//dont leave the page
	async stopGoto(href){
		//Check scroll to hash on same page
		let loc = window.location
		href = new URL(href, loc)
		//hashes
		if(loc.origin == href.origin && href.pathname == loc.pathname && href.hash){
			await this.scrollToLocationHashElement(href)
			return true
		}
		//I wanted my subdomains to be counted too... apparently not possible...
		if(loc.origin != href.origin){
			window.location.href = href.origin
			return true
		}
		return false
	},
	async linkClickedF(e) {
		let href = e.target.closest('[href]')
		if(!href){ return }
		if(href.dataset.nativeHref != undefined){return}
		href = href.getAttribute('href')
		if(!this.isLocalUrl(href, -99)){return}

		//prevent default if we do not handle
		//cancel their thing
		e.preventDefault()
		e.stopPropagation()

		await this.goto(href)
		return
	},
}