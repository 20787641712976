export default {
	unitName: "GoogleButton",
	onConnected(){
		if(!midflip.scriptjs ){
			midflip.scriptjs = require('scriptjs')
		}
		this.obs = midflip.mob(this.el, (() => {
			let fr = this.el.qs('iframe')
			if(fr.getAttribute('style').includes('-2px -10px;')){
				fr.setAttribute('style',
					(fr.getAttribute('style').
						split(';').
						filter((a) => {
							return !(a.includes('width') || a.includes('margin'))
						}).
						join(';')
					) + `; width: ${parseInt(this.el.dataset.width) + 20}px; margin: -2px -10px 0px 0px;`
				)
			}
		}).bind(this))

		midflip.scriptjs('https://accounts.google.com/gsi/client', () => {
			google.accounts.id.initialize({
					client_id: this.el.dataset.client_id,
					callback: (x) => {
						//the login_uri option just randomly stopped working so we do this callback thing with a dive.
						let credential = x.credential
						// console.log('your cred is', credential)
						otty.dive({url: this.el.dataset.login_uri, formInfo: {credential}})
					},
					auto_prompt: "false"
				});
			google.accounts.id.renderButton( this.el,  {
					theme: "outline",
					width: parseInt(this.el.dataset.width),
					type: "standard",
					size: "large",
					theme: "outline",
					text: "sign_in_with",
					shape: "rectangular",
					logo_alignment: "left"
				});
			google.accounts.id.prompt(); 
		})
	},
	onRemoved(){
		if(this.obs){this.obs.disconnect()}
	}
}